import { createApp } from 'vue'
import App from './app/App.vue'
import router from './app/app-routes'
import store from './app/app-state'

import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'
import './registerServiceWorker'

let vue = createApp(App).use(router).use(store);
vue.mount('#app');
