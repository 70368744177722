import { createStore } from 'vuex';

import authData from "@/app/shared/state/auth-data"

const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        authData
    }
});

export default store;
