import axios from 'axios';

import { authServiceBaseUrl } from '@/environment/environment';

/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const config = {
    baseURL: authServiceBaseUrl
};

const authServiceClient = axios.create(config);

/** Adding the response interceptors */
authServiceClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        /** Do something with response error */
        return Promise.reject(error);
    }
);

export { authServiceClient };
