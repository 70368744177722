<template>
  <ul id="userDropdown" class="dropdown-content">
    <li><router-link to="/guilds">Guilds</router-link></li>
    <li><a @click="logout" class="red-text">log out</a></li>
  </ul>
  <div class="navbar-fixed">
    <nav class="nav-extended teal">
      <div class="nav-wrapper teal">
        <a href="/" class="brand-logo"><img alt="Support-Manager" src="../assets/support-manager.png" class="circle"></a>
        <a href="#" data-target="nav-mobile" class="sidenav-trigger"><i class="material-icons">menu</i></a>
        <ul class="right hide-on-med-and-down">
          <li><router-link to="/">Home</router-link></li>
          <li v-if="user">
            <a class="dropdown-trigger valign-wrapper no-autoinit" data-target="userDropdown">
              <img alt="user avatar" :src="discordCdn.userAvatar(user)" class="circle" width="48" style="margin: 0.5em">
              {{ user.username }}
            </a>
          </li>
        </ul>
      </div>
      <router-view name="extendedNav"></router-view>
    </nav>
  </div>

  <ul class="sidenav" id="nav-mobile">
    <li v-if="user">
      <div class="user-view" id="sidenav-user-profile">
        <div class="background">
          <img v-if="user.banner" :src="discordCdn.userBanner(user, {size: 1024})" alt="user profile banner" class="responsive-img">
        </div>
        <img alt="user avatar" :src="discordCdn.userAvatar(user)" class="circle">
        <span class="white-text name">{{ user.username }}</span>
      </div>
    </li>
    <li><router-link to="/">Home</router-link></li>
    <li><router-link to="/guilds">Guilds</router-link></li>
    <li><a @click="logout" href="#" class="red-text">log out</a></li>
  </ul>

  <router-view/>
</template>

<script>
import { discordOauthUrl, logoutUrl } from "@/environment/environment";
import { discordCdn } from "@/app/shared/services";
import { mutationTypes } from "@/app/shared/state";
import M from "materialize-css";

export default {
  data() {
    return {
      discordCdn,
      discordOauthUrl,
    }
  },
  computed: {
    user() {
      return this.$store.state.authData.discordUser;
    }
  },
  watch: {
    user() {
      this.$nextTick(() => {
        M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {coverTrigger: false});
      });
    },
  },
  methods: {
    logout() {
      this.$store.commit(mutationTypes.LOGOUT);
      window.open(logoutUrl, "_self");
    },
  },
  created() {},
  mounted() {
    M.AutoInit();
  },
}
</script>

<style>
.brand-logo > img {
  width: 50px;
  margin: 3px 1em;
}
#sidenav-user-profile {
  padding-bottom: 1em !important;
}
#sidenav-user-profile > .background {
  background-color: darkslategrey;
}
</style>
