import * as idb from "idb";

function createDiscordDB() {
    return idb.openDB('discord', 1, {
        upgrade(db) {
            db.createObjectStore('guilds', {keyPath: 'id'});
            db.createObjectStore('users', {keyPath: 'id'});
        }
    });
}

function _strip(object) {
    return JSON.parse(JSON.stringify(object))
}

export const discordDb = {
    db: createDiscordDB(),
    get(storeName, key) {
        return this.db.then(db => {
            return db.get(storeName, key);
        })
    },
    getGuild(id) {
        return this.get('guilds', id);
    },
    getGuilds() {
        return this.db.then(db => {
            return db.getAll('guilds')
        })
    },
    insertGuilds(guilds) {
        return this.db.then(db => {
            const tx = db.transaction('guilds', 'readwrite');
            const store = tx.objectStore('guilds');
            return Promise.all(_strip(guilds).map(guild => store.put(guild)))
                .catch(() => {
                    tx.abort();
                    throw 'Failed inserting guilds into discord idb';
                });
        })
    },
    getUser(id) {
        return this.get('users', id);
    },
    putUser(user) {
        return this.db.then(db => {
            return db.put('users', _strip(user));
        })
    },
}
