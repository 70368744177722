import axios from 'axios';
import store from "@/app/app-state";

import { discordApiBaseUrl } from '@/environment/environment';

/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const config = {
    baseURL: discordApiBaseUrl
};

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different config
 * Only this client will be used rather than axios in the application
 **/
const discordHttpClient = axios.create(config);

/**
 * Auth interceptors
 * @description Add auth token to request header
 * @param {*} config
 */
const authInterceptor = config => {
    const token = store.state.authData.discordAccessToken;
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
};

const loggerInterceptor = config => {
    /** Add logging here */
    return config;
};

/** Adding the request interceptors */
discordHttpClient.interceptors.request.use(authInterceptor);
discordHttpClient.interceptors.request.use(loggerInterceptor);

/** Adding the response interceptors */
discordHttpClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        /** Do something with response error */
        return Promise.reject(error);
    }
);

export { discordHttpClient };
