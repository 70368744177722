/**
 * see: https://discord.com/developers/docs/reference#image-formatting-cdn-endpoints
 */

import {discordCdnBaseUrl as BASE_URL} from '@/environment/environment'

/**
 *
 * @param resource
 * @param allowAnimated {boolean} Whether or not to allow GIFs
 * @param size
 * @return {string}
 * @private
 */
function _tail(resource, {allowAnimated=true, size=null}) {
    const params = size === null ? '' : `?size=${size}`;
    return `.${resource.startsWith('a_') && allowAnimated ? 'gif' : 'png'}${params}`
}

let discordCdn = {
    /**
     * Return the CDN URL for a guild icon.
     * @param guild {Object} The guild that the icon is from
     * @param options {Object}
     * @return {string} URL to the icon
     */
    guildIcon(guild, options = {}) {
        if (!guild.icon) return "";
        return `${BASE_URL}icons/${guild.id}/${guild.icon}${_tail(guild.icon, options)}`;
    },

    /**
     * Return the CDN URL for a user avatar.
     * @param user {Object} The user that the avatar belongs to
     * @param options {Object}
     * @return {string} URL to the avatar
     */
    userAvatar(user, options = {size: 64}) {
        if (!user.avatar) {
            return `${BASE_URL}embed/avatars/${user.discriminator % 5}.png`;
        }
        return `${BASE_URL}avatars/${user.id}/${user.avatar}${_tail(user.avatar, options)}`;
    },

    /**
     * Return the CDN URL for a user profile banner.
     * @param user {Object} The user that the banner belongs to
     * @param options {Object}
     * @return {string} URL to the banner
     */
    userBanner(user, options= {}) {
        if (!user.banner) return "";
        return `${BASE_URL}banners/${user.id}/${user.banner}${_tail(user.banner, options)}`;
    }
};

export {discordCdn};
