import Cookies from "js-cookie";
import {authServiceClient, discordDb, discordHttpClient} from "@/app/shared/services";

export const mutationTypes = {
    SET_DISCORD_ACCESS_TOKEN: "SET_DISCORD_ACCESS_TOKEN",
    SET_WEBSERVICE_ACCESS_TOKEN: "SET_WEBSERVICE_ACCESS_TOKEN",
    LOGOUT: "LOGOUT",
    SET_DISCORD_USER: "SET_DISCORD_USER",
};

export const actionTypes = {
    RETRIEVE_WEBSERVICE_ACCESS_TOKEN: "RETRIEVE_WEBSERVICE_ACCESS_TOKEN",
    RETRIEVE_DISCORD_USER: "RETRIEVE_DISCORD_USER",
    LOGIN: "LOGIN",
};

export default {
    state: {
        discordAccessToken: Cookies.get("discord_access_token"),
        webserviceAccessToken: Cookies.get("webservice_access_token"),
        discordUser: null,
    },
    mutations: {
        [mutationTypes.SET_DISCORD_ACCESS_TOKEN](state, token) {
            Cookies.set("discord_access_token", token, {path: "/"});
            state.discordAccessToken = token;
        },
        [mutationTypes.SET_WEBSERVICE_ACCESS_TOKEN](state, token) {
            Cookies.set("webservice_access_token", token, {path: "/"});
            state.webserviceAccessToken = token;
        },
        [mutationTypes.LOGOUT](state) {
            Cookies.remove("discord_access_token", {path: "/"})
            Cookies.remove("webservice_access_token", {path: "/"})
            state.discordAccessToken = null;
            state.webserviceAccessToken = null;
        },
        [mutationTypes.SET_DISCORD_USER](state, user) {
            state.discordUser = user;
        },
    },
    actions: {
        async [actionTypes.RETRIEVE_WEBSERVICE_ACCESS_TOKEN](context) {
            const resp = await authServiceClient.post("/", {auth_token: context.state.discordAccessToken});
            if (resp.status !== 200) {
                throw `Failed to retrieve token: ${resp.statusText}`;
            }
            context.commit(mutationTypes.SET_WEBSERVICE_ACCESS_TOKEN, resp.data.auth_token);
        },
        async [actionTypes.RETRIEVE_DISCORD_USER](context) {
            // get user via "network first" strategy
            const user = await discordHttpClient.get("/users/@me")
                .then((resp) => {
                    localStorage.setItem("user_id", resp.data.id)
                    discordDb.putUser(resp.data)
                    return resp.data
                })
                .catch(() => {
                    // fallback to cache when network fails
                    return discordDb.getUser(localStorage.getItem("user_id"))
                });
            context.commit(mutationTypes.SET_DISCORD_USER, user);
        },
        async [actionTypes.LOGIN](context, token) {
            context.commit(mutationTypes.SET_DISCORD_ACCESS_TOKEN, token);
            await Promise.allSettled([
                context.dispatch(actionTypes.RETRIEVE_DISCORD_USER),
                context.dispatch(actionTypes.RETRIEVE_WEBSERVICE_ACCESS_TOKEN)
            ]);
        },
    }
}
