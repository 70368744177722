import * as idb from "idb";

function createSmbDB() {
    return idb.openDB('smb', 1, {
        upgrade(upgradeDB) {
            upgradeDB.createObjectStore('guilds', {keyPath: 'id'});
            const ticketStore = upgradeDB.createObjectStore('tickets', {keyPath: ['guildId', 'id']});

            ticketStore.createIndex('guild', 'guildId');
        }
    });
}

export const webserviceDb = {
    db: createSmbDB(),
    getAll(storeName) {
        return this.db.then(db => {
            return db.getAll(storeName);
        })
    },
    insert(storeName, protoMessages) {
        return this.db.then(db => {
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            return Promise.all(protoMessages.map(protoMsg => store.put(protoMsg.toObject())))
                .catch(() => {
                    tx.abort();
                    throw 'Failed inserting objects into webservice idb';
                });
        })
    },
    // guilds
    getGuilds() {
        return this.getAll('guilds');
    },
    insertGuilds(guilds) {
        return this.insert('guilds', guilds);
    },
    // tickets
    getGuildTickets(guildId) {
        return this.db.then(db => {
            return db.getAllFromIndex('tickets', 'guild', guildId);
        });
    },
    insertTickets(tickets) {
        return this.insert('tickets', tickets);
    },
}
