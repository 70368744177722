<template>
  <div class="home container center-align">
    <h1>Welcome to the SMB dashboard!</h1>
    <img alt="Support-Manager logo" src="@/assets/logo.png" class="responsive-img">
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
