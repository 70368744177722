import { SupportManagerPromiseClient } from "@grpc-interface/supportmanager";
import { StatusCode } from "grpc-web";
import { webserviceBaseUrl } from "@/environment/environment";
import store from "@/app/app-state";
import {actionTypes} from "@/app/shared/state";

/**
 * @constructor
 * @implements {UnaryInterceptor}
 */
const AuthInterceptor = function() {};

/** @override */
AuthInterceptor.prototype.intercept = function(request, invoker) {
    // Update the request metadata before the RPC.
    const reqMetadata = request.getMetadata();
    reqMetadata.Authorization = `Bearer ${store.state.authData.webserviceAccessToken}`;

    return invoker(request).catch(response => {
        if (response.code === StatusCode.UNAUTHENTICATED) {
            console.log("refreshing webservice access token")
            return store.dispatch(actionTypes.RETRIEVE_WEBSERVICE_ACCESS_TOKEN).then(() => invoker(request))
        }
        return Promise.reject(response)
    });
};

export const webserviceClient = new SupportManagerPromiseClient(
    webserviceBaseUrl,
    {},
    {unaryInterceptors: [new AuthInterceptor()]}
);
