const {
    VUE_APP_WEBSERVICE_BASE_URL,
    VUE_APP_LOGOUT_URL,
    VUE_APP_AUTH_SERVICE_BASE_URL,
    VUE_APP_DISCORD_API_BASE_URL,
    VUE_APP_DISCORD_CDN_BASE_URL,
    VUE_APP_DISCORD_OAUTH_URL,
    NODE_ENV = ''
} = process.env;

const environment = NODE_ENV.toLowerCase();
const webserviceBaseUrl = VUE_APP_WEBSERVICE_BASE_URL;
const logoutUrl = VUE_APP_LOGOUT_URL;
const authServiceBaseUrl = VUE_APP_AUTH_SERVICE_BASE_URL;
const discordApiBaseUrl = VUE_APP_DISCORD_API_BASE_URL;
const discordCdnBaseUrl = VUE_APP_DISCORD_CDN_BASE_URL;
const discordOauthUrl = VUE_APP_DISCORD_OAUTH_URL;

export {
    environment,
    webserviceBaseUrl,
    logoutUrl,
    authServiceBaseUrl,
    discordApiBaseUrl,
    discordCdnBaseUrl,
    discordOauthUrl,
};
